/*global $*/

import 'bootstrap';

class Quote
{
    constructor()
    {
        this.initQuote();

        $("#quoteModal").on('hide.bs.modal', function(){
            $.ajax({
                url: '/?action=balhousie/quote/close-modal',
                method: 'GET',
            });
        });
    }

    initQuote()
    {
        if ($("#quoteModal").length && $("#quoteModal").data('show-modal') != 1) {
            this.getModalData(1, 0, 0, '');
        }
    }

    getModalData(step, nextQuestion, lastQuestion, page)
    {
        const _this = this;
        const popupBody = $("#quoteModal .modal-body");
        popupBody.html("");

        $.ajax({
            type: 'get',
            url: '/ajax/getQuote',
            dataType: 'html',
            data: {
                step: step,
                q: nextQuestion,
                lastQuestion: lastQuestion,
                page: page
            },
            processData: true,
            success: function(result) {
                popupBody.html(result);
                _this.initForm();
            }
        });

        $('#quoteModal').modal({show:true});
    }

    initForm()
    {
        const _this = this;

        $(".quoteForm").submit(function (e) {
            e.preventDefault();

            const formData = $(this).serialize();
            $.ajax({
                url: '/?action=balhousie/quote/create-quote',
                data: formData,
                method: 'POST',
            }).done((data) => {
                _this.getModalData(data.nextStep, data.redirect, data.lastQuestion, data.page);
            }).fail(() => {
                
            }).always(() => {
                
            });
        });
    }
}

new Quote;